.ics-content-wrapper, .ics-content-wrapper-navbar {
  position:fixed;
  // overflow: auto;
  top:75px;
  bottom:30px;
  right:0;
  left:0;
}

.ics-content-wrapper-navbar {
  left: 340px;
  //height: 1335px;
  width: 2220px;

  .menu-collapsed & {
    left: 100px;
    width: 2460px;
  }

  // tables are less wide in artists 
  // section because of right sidebar 
  &.-artists {
    width: 1720px;

    .menu-collapsed & {
      width: 1960px;
    }
  }

  .choices-table {
    md-checkbox {
      margin-bottom: 0;
    }
  }
}

.ng-table-pager {
  display: none;
}

.icsa-button {
  min-width: 100px;
}

.fl-r {
  float: right;
}

.cur-p {
  cursor: pointer;
}

.text {

  &-red {
    color: red;
  }

  &-green {
    color: green;
  }

  &-black {
    color: black;
  }

  &-white {
    color: white;
  }
  
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}