.ics-admin {
  // width: 100%;
  border:1px solid #c0c0c0; border-bottom: none;

  thead {
    th {
      background-color: #eeeeee;
      padding: 5px;
      text-align: center;
      font-weight: 700;
      border-right: 1px solid #c0c0c0;
      border-bottom: 1px solid #c0c0c0;

      &:last-child {
        border-right: none;
      }
    }
  }

  td {
    border-right:none;
    border-bottom: 1px solid #C0C0C0;
    padding: 5px;
    line-height: 1;
  }

  &.table td{
    vertical-align:middle;
  }

  &.__email {
    md-switch{
      margin:0;
    }
    md-switch.md-default-theme.md-checked, md-switch.md-checked .md-ink-ripple {
      &:before {
        background-color: $green;
      }
    }
  }

  &.__email-custom,
  &.__email {
    .icon-edit{
      color:#333;
    }
  }
}

md-dialog-actions {
  .md-button.md-primary.md-default-theme {
    border-radius: 8px;
    padding: 4px 15px;
    outline: none;
    text-transform: uppercase;
    font-weight: 700;
    min-height: 24px;
    line-height: 24px;
    box-sizing: border-box;

    &.md-cancel-button {
      background: linear-gradient(to bottom, #ffc87e, #ff9b00);
      border: 2px solid #e1a453;
      color: #000;
    }

    &.md-confirm-button {
      background: linear-gradient(to bottom, #fefefe, #ebebeb);
      border: 2px solid #d5d5d5;
      color: #000;
    }
  }
}

.email-filters {
  list-style-type: none;
  padding: 0;
  margin-top: 20px;

  li {
    margin: 1px 0;
    a {
      display: block;
      width: 95%;
      margin-left: 5%;

      background-color: #eeeeee;
      text-decoration: none;
      font-weight: 700;
      padding: 6px 0 6px 10px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border: 1px solid #eeeeee;
      border-right: 0;


      &:hover, &:active, &.active {
        background-color: #fff;
        border-color: #C0C0C0;
        width: 100%;
        margin-left: 0;
      }
    }
  }
}

div[data-name="color"] .dropdown-menu {
  min-width: 345px !important;
  padding: 10px;
}

.modal-backdrop.in {
  display: none;
}

.note-editor .modal-dialog {
  margin-top: 15%;
}

.md-datepicker-calendar-pane {
  background: #fff;

  .md-datepicker-input-mask {
    width: auto !important;
  }

}

.email-wrapper {
  background-color: $grey-ll;
  padding: 20px;
  text-align: center;
  color: #fff;
  max-width: 680px;

  a {
    color: #fff;
    text-decoration: underline;
  }

  &.__header {
    background-color: #fff;
    color: #000;
    img {
      max-width: 100%;
    }
  }

  &.__body {
    background-color: #fff;
    color: #000;
    text-align: left;

    a {
      text-decoration: inherit;
    }
  }

  &.__footer {
    background-color: #fff;
    color: #000;
    margin-bottom: 20px;

    a {
      color: $blue;
    }
  }
}

.btn-back{
  max-width:100px;
  margin:20px 0 0 20px;
}

.btn-email{
  max-width:180px;
}

.timePicker {
  display: inline-flex;
  margin: 0 0 0 40px;

  a {
    padding: 0;
  }

  input.form-control {
      height:auto;
      padding: 0;
      width:40px !important;
  }
}
