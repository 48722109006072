/**
 *  If you want to override some bootstrap variables, you have to change values here.
 *  The list of variables are listed here bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_variables.scss
 */
$fa-font-path: "../fonts";

$navbar-inverse-link-color: #5AADBB;
$icon-font-path: "../../bower_components/bootstrap-sass-official/assets/fonts/bootstrap/";
// $mdi-font-path:        "../../bower_components/mdi/fonts" !default;
// $mdi-font-size-base:   24px !default;
// $mdi-css-prefix:       mdi !default;
// $mdi-version:          "1.2.64" !default;

  /**
   *  Do not remove this comments bellow. It's the markers used by wiredep to inject
   *  sass dependencies when defined in the bower.json of your dependencies
   */
// bower:scss
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/_bootstrap.scss";
@import "../../bower_components/font-awesome/scss/font-awesome.scss";
// endbower
@import "scss/variables";
@import "scss/mixins/button";

*:focus {
  outline: 0;
}
html {
  font-size: 12px;
}
.font-repair{color:$red;}
.font-select{color:$orange;}
.font-design{color:$purple;}

.ics-tab{
  text-transform: none;
}
.ics-content-wrapper,
.ics-content-wrapper-navbar
{
  position:fixed;
  top:75px;
  bottom:30px;
  right:0;
  left:0;
}
.ics-content-wrapper-navbar{left:110px; }
.ics-content-wrapper{overflow:scroll;}
.ics-tab{
  padding:8px 10px 6px 10px;
  width:100px;
  border-radius: 3px;
  cursor: pointer;
  -webkit-box-shadow: $nav-shadow;
  -moz-box-shadow: $nav-shadow;
  box-shadow: $nav-shadow
}

md-tab-content table{width: 100%;border: 2px solid #cccccc;border-right-width: 1px;border-bottom-width:1px;border-collapse: separate;
  border-spacing: 0;}
thead{height:44px;}
md-tab-content td, md-tab-content th, .question-list td, .question-list th, .lists-table td { padding: 3px; border-right: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;text-align:center;line-height: .95em;min-height:30px;}
.question-list td{text-align: left;line-height: 1.5em;vertical-align: top;padding:8px 8px 0px 8px}

md-tab-content td {
  line-height: 1.5em;
}
table.question-list{border:2px solid #cccccc;border-bottom:none;width:100%}
md-tab-content th {
  background-color: #f3f3f3;
  line-height: 24px;
  padding: 12px 10px;
}
.md-tab {padding:12px 10px;}
.artist-table tr.even, .pending-table tr.even, .lists-table tr.even{

  background: -moz-linear-gradient(top,  rgba(211,211,211,0.12) 0%, rgba(142,142,142,0.29) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(211,211,211,0.12)), color-stop(100%,rgba(142,142,142,0.29))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  rgba(211,211,211,0.12) 0%,rgba(142,142,142,0.29) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  rgba(211,211,211,0.12) 0%,rgba(142,142,142,0.29) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  rgba(211,211,211,0.12) 0%,rgba(142,142,142,0.29) 100%); /* IE10+ */
  background: linear-gradient(to bottom,  rgba(211,211,211,0.12) 0%,rgba(142,142,142,0.29) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1fd3d3d3', endColorstr='#4a8e8e8e',GradientType=0 ); /* IE6-9 */

}
.artist-table tr.artist-tr.odd:hover, .artist-table tr.artist-tr.even:hover{
  cursor:pointer;

  background: -moz-linear-gradient(top,  rgba(188,188,188,0.7) 0%, rgba(188,188,188,1) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(188,188,188,0.7)), color-stop(100%,rgba(188,188,188,1))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  rgba(188,188,188,0.7) 0%,rgba(188,188,188,1) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  rgba(188,188,188,0.7) 0%,rgba(188,188,188,1) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  rgba(188,188,188,0.7) 0%,rgba(188,188,188,1) 100%); /* IE10+ */
  background: linear-gradient(to bottom,  rgba(188,188,188,0.7) 0%,rgba(188,188,188,1) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b3bcbcbc', endColorstr='#bcbcbc',GradientType=0 ); /* IE6-9 */



}
.artist-table tr.active-tr, .artist-table tr.artist-tr.even.active-tr:hover, .artist-table tr.artist-tr.odd.active-tr:hover{

  background: -moz-linear-gradient(top,  rgba(255,197,120,0.8) 0%, rgba(255,153,0,1) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,197,120,0.8)), color-stop(100%,rgba(255,153,0,1))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  rgba(255,197,120,0.8) 0%,rgba(255,153,0,1) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  rgba(255,197,120,0.8) 0%,rgba(255,153,0,1) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  rgba(255,197,120,0.8) 0%,rgba(255,153,0,1) 100%); /* IE10+ */
  background: linear-gradient(to bottom,  rgba(255,197,120,0.8) 0%,rgba(255,153,0,1) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ccffc578', endColorstr='#ff9900',GradientType=0 ); /* IE6-9 */
  -webkit-box-shadow: 0px 0px 5px 2px rgba(247,160,10,0.57);
  -moz-box-shadow: 0px 0px 5px 2px rgba(247,160,10,0.57);
  box-shadow: 0px 0px 5px 2px rgba(247,160,10,0.57);
}
md-tab-content table .tablesort-asc, md-tab-content table .tablesort-desc {
  background-color: #d6d6d6;
}

// Round active inactive visual representation
div.on-button,
div.off-button{
  border-radius:10px;
  border:3px solid #7a7a7a;
  height:20px;width:20px;margin:0px auto;
}
div.on-button{background-color: #009049;}
div.off-button{background-color: #ef242b;}

// Temp fixes //
.layout-column > .flex-5 {
  &.adminTabs { max-height: 8%; }
}

md-tabs-wrapper {
  display: block;
  padding-top: 10px;
  z-index: 2;
}
.ics-ltgrey-bg{
  background-color: #efefef;
  color:black;
  font-weight: 800;
}

md-tabs md-tabs-wrapper {
  background-color: #fff;
}
md-tabs.md-dynamic-height md-tabs-content-wrapper {
  margin-bottom:30px;
}

// Switch
md-switch.on-off-switch .md-thumb {
  background-color: #ef242b;
}

md-switch.on-off-switch.md-checked .md-thumb {
  background-color: #009049;
}

md-switch.on-off-switch[disabled] .md-thumb {
  background-color: rgb(189,189,189);
}
//Register Form
md-checkbox .md-container{top:7px;}
md-checkbox {line-height: 1.3em;margin-left:25px;}
md-radio-button{line-height: 1.3em;}
md-radio-button .md-container{top:3px;}
//Demo Fieldset

legend {
  color: #3F51B5; }
fieldset.standard {
  border-style: solid;
  border-width: 1px;
  border-color: #eeeeee;
  border-radius: 5px;

}
.registration legend, .contact-legend {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-image: none;
  border-style: none;
  border-width: 0;
  display: block;
  line-height: inherit;
  margin-bottom: 5px;
  margin-left: 20px;
  padding: 5px 5px 0;
  width: auto;
  font-size:1.25em;
}
.md-dialog-container legend{padding:0 5px;}
.radio-register{margin-top:-10px;padding-left:10px;}
.text-fieldset, .text-fieldset-req{padding:0px 25px 25px 25px;}
.text-fieldset legend{margin-left:0;}
.textarea-register{width: 100%;border:1px solid #c0c0c0;border-radius: 5px;padding:4px;}
.bad-answer{color: $red;}



.animate-show.ng-hide-add.ng-hide-add-active,
.animate-show.ng-hide-remove.ng-hide-remove-active {
  -webkit-transition: all linear 0.8s;
  transition: all linear 0.8s;
}
.ng-hide:not(.ng-hide-animate) {
  /* this is just another form of hiding an element */
  display: block!important;
  position: absolute;
  top: -9999px;
  left: -9999px;
}
.animate-show.ng-hide-add.ng-hide-add-active{
  display: block!important;
}
#user-profile {
 max-width:500px;position:relative;height:100%;right:0;
  -webkit-box-shadow: -2px 0px 5px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: -2px 0px 8px 0px rgba(0,0,0,0.25);
  box-shadow: -2px 0px 8px 0px rgba(0,0,0,0.25);
  background-color: #ffffff;z-index:5;


}
.hide-user{
  visibility: hidden;
  width:0px;
  -webkit-transition:width, .2s linear;
  -moz-transition: width, .2s linear;
  -ms-transition: width, .2s linear;
  -o-transition: width, .2s linear;
  transition: width, .2s linear;}
.show-left{
  visibility: visible;
  width:100%;
  -webkit-transition:width, .4s linear;
  -moz-transition: width, .4s linear;
  -ms-transition: width, .4s linear;
  -o-transition: width, .4s linear;
  transition: width, .4s linear;}
#user-profile md-select:focus:not([disabled]) .md-select-value{
  border-bottom: none;
}
#user-profile .md-select-value *:first-child {
  flex: 1 1 0;
  height: 18px;
  max-width: 40px;
  overflow: hidden;
  text-overflow: clip;
  transform: translate3d(0px, 2px, 0px);
  white-space: normal;
}
#user-profile md-switch{margin:2px;}
#user-profile md-select-value{padding:0; border: none;height: 30px;
  margin-top: -5px;}
.md-select-value .md-select-icon {
  margin: 0 0 0 -8px;
  width: 14px;
}
#user-profile md-input-container{padding:0;}
#user-profile md-input-container > md-select {
  margin: 0 0;
}
#user-profile h2{font-weight:800;font-size:.8em;line-height: 1.3em;}
md-toast.md-top {
  top: 85px;
  z-index:99;
}
.user-profile-toggle-button{
    border-left: 2px solid #dddddd;
  border-bottom:2px solid #dddddd;
  height:38px;
  min-height: 38px;
    margin-left: -30px;
    margin-top: 0px;
    padding-top: 5px;
  padding-bottom:5px;
  padding-left:0;
    position: absolute;
    width: 40px;min-width:40px;z-index:9;
  border-radius:0 0 0px 5px;
  background-color: #efefef;
}
.user-profile-toggle-button:hover{

}
.note-submit {
  margin:0px 0px 0px 5px;
  padding: 2px 10px 0 10px;
  max-height: 35px;
  bottom: 0;
  border-radius: 5px;
  height: 35px;
}
.note-text {
  max-width: 410px;
  min-height: 35px;
  height:45px;
  margin-left: 5px;
  background-color: #bbb;
  color:#000;
  border:1px solid #666;
}

.user-top-heading{
  display: block;
  padding-top:9px;
}
md-input-container .md-input {
line-height: normal;
}
.color-wheel{
  background: url("/assets/images/color-wheel.png") no-repeat 4px 7px;
}
.no-data-graph{
  background: url("/assets/images/no-data-graph.png") no-repeat 4px 7px;
}
md-pagination-wrapper {
  position: relative;
  display:inline-block;
  width:100%;
}
/* Question List Page */
.questions-h2{margin-left:20px;}
.question-list-choices li{display:inline;padding:0 30px 0 0;}
.question-list ul{margin:0;padding:0px 0 5px 0;}
.question-list-select{list-style: none;}
/* Question Edit Form */
.options-input{
  height:35px;
  min-width: 320px;
  padding:0 0 0 4px;
  border:1px solid #c0c0c0;
  border-radius:3px;
}
.option-title{
  text-align: center;
  min-width: 100px;
  font-weight: bold;
}
.remove-question{
  min-width:30px;
  line-height:20px;
  font-weight:bold;
  border-radius:20px;
  font-size:1.5em;
  min-height:30px;
  max-height: 30px;
  background-color: #efefef;
}
button.md-raised.add-question{
  margin-left:331px;
  min-width:30px;
  min-height:30px;
  font-weight:bold;
  line-height:10px;
  border-radius:20px;
  font-size:1.5em;
  color:#fff;
  background-color: #000;
  max-height: 30px;
}
.question-legend{
  background-color: #efefef;
  border-radius: 5px;
  padding:5px;
  width:100%;
  margin-bottom:10px;
  height:40px;
  border:1px solid #c0c0c0;
  overflow: hidden;
}
.question-legend-label{
  padding:4px 0 0 0;
  display:inline-block;
  margin:0 0 0 5px;
  font-weight: bold;
}
.legend-checkbox {
  display: inline-block;
  margin: 6px 0 0 50px;
}
.legend-checkbox-info {
  display: inline-block;
  margin:0;
  padding:4px 0 0 0;
}
.legend-switch-off {
  display: inline-block;
  margin: 2px 0 0 50px;
}
.legend-switch-off-info {
  display: inline-block;
  margin:0;
  padding:4px 0 0 0;
}
.legend-switch-on {
  display: inline-block;
  margin: 2px 0 0 50px;
}
.legend-switch-on-info {
  position: absolute;
  margin:0;
  padding:4px 0 0 0;
}
.question-legend md-checkbox[disabled].md-checked .md-icon {
  background-color: rgba(117,117,117,0.87);
}
.question-legend md-switch[disabled] .md-thumb {
  background-color: #ef242b;
}
.question-legend md-switch[disabled].md-checked .md-thumb {
  background-color: #009049;
}
.question-list td{border-right:none;border-bottom:1px solid $orange;padding:8px;}
.question-list tr.last td{border-bottom:2px solid #ccc;}
.choices-table td{border-right:none;border-bottom:1px solid #C0C0C0;padding:3px 5px 0 5px;line-height:2em}
.choices-table {border:1px solid #c0c0c0; border-bottom: none;}
.choices-table div.on-off-neutral {
  background-color: #c0c0c0;
  opacity: 0.5;
}
.choices-table div.on-off-neutral,
.choices-table div.on-button,
.choices-table div.off-button{
  margin-top:5px;
}
.choices-table md-checkbox[disabled].md-checked .md-icon {
  background-color: rgba(117,117,117,0.87);
}
/* Pending Artists */
.notification {
  height: 25px;
  min-width: 25px;
  padding: 0 3px;
  display: block;
  position: absolute;
  background-color: $red;
  right: 5px;
  top: 3px;
  color: #fff;
  font-weight: bold;
  border-radius: 15px;
  line-height: 26px;
  text-align: center;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.55);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.55);
}
.md-button.md-raised.approve-app, .md-button.md-raised.reject-app, .md-button.md-raised.app-link{padding:5px 10px;margin:0px;max-height:30px;min-height:30px;line-height:18px;color:#fff;font-size:14px;font-weight:bold;}
.md-button.md-raised.app-link{color:#000;font-weight:normal;}
.md-button.md-raised.approve-app{background-color:$green;}
.md-button.md-raised.reject-app{background:$red;}
.md-button.md-raised.approve-app:hover{background:$green-hl;}
.md-button.md-raised.reject-app:hover{background: $red-hl;}



/* Registration Form */
.registration-form .contact-info md-input-container{padding:20px;}
.registration-form .contact-info{padding:20px;}
.registration-form form{width: 100%;}
.registration-form fieldset{margin-bottom:30px;}
.registration-form md-whiteframe{max-width: 950px;margin:30px auto 50px auto;}
.email-msg-block{background:#efefef;border-radius:5px;padding:8px 5px 5px 5px;max-width:200px;margin-left:20px;}
.dob-calendar{max-width: 30px;min-width:30px;max-height:30px;min-height:30px;padding:0;margin:-30px 0 0 0;line-height:30px;font-size:18px;}
.app-dialog{max-width: 500px;float:left;}
.app-dialog h3{float:left;width:350px;margin-top:0;margin-left:20px;}
.app-icon{font-size:40px;padding-right:20px;float:left;width:40px;}
.registration  md-input-container{
  padding-bottom: 16px;
}

.text-fieldset-req legend{color:#bbb;font-size:14px;font-weight:bold;margin-left:-20px;margin-bottom:20px;}
md-dialog .md-actions.app-dialog-action .md-button {margin: 20px auto;padding:0px 20px;}
input, select, textarea{font-size: 1.1em}
.pending-tab{float:right;right:20px;}
.pending-adjusted{right:520px;
  -webkit-transition:right, .2s linear;
  -moz-transition: right, .2s linear;
  -ms-transition: right, .2s linear;
  -o-transition: right, .2s linear;
  transition: right, .2s linear;}
.choices-tr{border-bottom: 1px solid #c0c0c0}
.highlight{border:8px solid #000; background:#ffff99;}
legend.display-legend {
  color: #000;
  font-weight: bold;
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-image: none;
  border-style: none;
  border-width: 0;
  display: block;
  line-height: inherit;
  margin-bottom: 5px;
  margin-left: -5px;
  padding: 5px 5px 0;
  width: auto;
  font-size: 1.1em;
}
.view-application md-input-container .md-input[disabled],
.view-application [disabled] md-input-container .md-input {
  border-bottom-color: transparent;
  color: #000;
  font-weight:bold;
  background-image: linear-gradient(to right, rgba(0,0,0,0.26) 0%, rgba(0,0,0,0.26) 33%, transparent 0%);
  background-image: -ms-linear-gradient(left, transparent 0%, rgba(0,0,0,0.26) 100%);
}

.lists-table {
  margin: 0px;
  width: 100%;
  border: 1px solid #c0c0c0;
  border-bottom:none;
  height:100%;
  min-height:100%;
}
  .lists-table thead tr th {
    text-align: center;
    border-right: 1px solid #c0c0c0;
    border-bottom:1px solid #c0c0c0;
  }

.lists-table td{padding:4px 2px;line-height: 1.5em}
.hide{display: none;visibility: hidden;}
.list-intro{min-height: 100%;height:785px;vertical-align: top;padding-top:35px;padding-left:20px}
.disable-btn{opacity: .5;}
/**
 *  Do not remove this comments bellow. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
// injector
@import "admin/bmd.scss";
@import "admin/roles.scss";
@import "main/main.scss";
@import "payments/payments.scss";
@import "scss/_variables.scss";
@import "scss/artist-job.scss";
@import "scss/background-colors.scss";
@import "scss/buttons.scss";
@import "scss/job-capacity.scss";
@import "scss/scrollbars.scss";
@import "scss/shared.scss";
@import "components/footer/footer.scss";
@import "components/malarkey/malarkey.scss";
@import "components/header/header.scss";
@import "components/navbar/navbar.scss";
@import "admin/partials/admin.scss";
@import "admin/partials/reset-capacity.scss";
@import "admin/partials/templates.scss";
@import "email/partials/email.scss";
@import "lists/partials/lists.scss";
@import "question/partials/question.scss";
@import "scss/mixins/button.scss";
@import "admin/partials/pay-periods/pay-periods.scss";
// endinjector
