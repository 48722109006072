.pay-periods {
  .pay-periods {
    text-transform: capitalize;
  }

  textarea {
    color: #FFFFFF !important;
  }
}

.pay-period__bmd {
  i {
    cursor: pointer;
    color: red;

    &.-active {
      color: green;
    }
  }
}

.ng-table-pager {
  display: block;
}

.ng-table-counts.btn-group {
  display: none;
}

.pay-period-modal {

  md-datepicker.md-pbOffice-theme {
    background: #000000 !important;
  }

  .md-pbOffice-theme .md-datepicker-input {
    background: #000000;
  }

  .md-pbOffice-theme .md-datepicker-input {
    color: #FFFFFF;
  }

  .md-datepicker-input {
    font-size: 16px;
  }

  .__date {
    margin-bottom: 30px;
  }

  .__date label {
    width: 50px;
    display: inline-block;
  }

  .pay-period__bmd {
    margin: 5px 0;
  }

  md-input-container {
    margin: 0;
  }

  .timePicker {
    float: right;
    margin: 0;
  }

  .fromDate, .toDate {
    display: block;

    &:before, &:after {
      content: '';
      display: table;
      clear: both;
    }
  }
}

.menu-collapsed .ics-content-wrapper-navbar {
  width: 1800px;
}