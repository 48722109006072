.artist-job-status {
  font-size: 22px;

  &.artist-job-status--checkmark {
    color: green;
  }

  &.artist-job-status--x {
    color: red;
  }
}