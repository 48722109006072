.acme-malarkey {
  text-transform: capitalize;
  color: #cb3837;

  &:after {
    animation: cursor-blink 0.4s linear infinite;
    content: "|";
    color: #cb3837;
  }
}

@keyframes cursor-blink {
  1% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
