.backgroundTemplates {
  position: relative;
  width: 100%;
  .devider {
    border: 1px solid #eeeeee;
  }
  .newTemplate {
    position: absolute;
    top: 15px;
    right: 0px;
  }
  .templatesTable {
    margin: auto;
    margin-top: 150px;
    width: 255px;
    .theading {
      background-color: #000000;
      color: #ffffff;
    }
    .tsubHeading {
      background-color: #f0f0f0;
    }
     th, td {
       width: 50%;
      padding: 12px 5px;
      border: 1px solid gray;
      text-align: center;
    }
    .psdTable {
      td > i{
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
}

.uploadPsdTemplate {
  .mainContent {
    .md-dialog-content {
      .templateUploadSizeText {
        width: 500px;
        height: 200px !important;
        border: 1px solid gray;
      }
      .uploadFile > label {
        margin-top: 20px;
      }
      .uploadFile > input {
        display: block;
        width: 100%;
        border: none;
        border-bottom: 1px solid gray;
      }

      .uploadFile > .hiddenInput {
        height: 0.1px;
        width: 0.1px;
        position: absolute;
        top: 0;
      }

      .uploadFile > .uploadButton {
        margin-top: 15px;
        margin-left: 0;
        height: 37px;
        padding-top: 0;
        padding-bottom: 0;
        font-weight: 800;
      }
    }
  }
}

