.question-list {
  thead {
    tr {
      background-color: #eeeeee;
      height: 40px;

      th {
        min-width: 80px;

        &.order {
          width: 50px;
          min-width: 50px;
        }

        &.actions {
          width: 125px;
        }
      }
    }
  }
  tbody {
    tr {
      background-color: #fff;

      &.ics-black-bg {
        background-color: #000;
      }
    }
  }

  .text-center {
    text-align: center;
  }
}

.question-tabs {
  
}

.choices-table {
  width: 100%;

  thead {
    tr {
      th {
        &.option-title {
          width: 40px;
        }

        &.text-left {
          text-align: left;
        }

      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #c0c0c0;
    }
  }
}

/* Question List Page */

.check-correct-answer {
  margin: 5px 0 0 calc(50% - 10px) !important;
}

.questions-h2{margin-left:20px;}
.question-list-choices li{display:inline;padding:0 30px 0 0;}
.question-list ul{margin:0;padding:0px 0 5px 0;}
.question-list-select{list-style: none;}
/* Question Edit Form */
.options-input{
  height:35px;
  min-width: 320px;
  padding:0 0 0 4px;
  border:1px solid #c0c0c0;
  border-radius:3px;
}
.option-title{
  text-align: center;
  min-width: 100px;
  font-weight: bold;
}
.remove-question{
  min-width:30px;
  min-height:30px;
  max-height: 30px;
  font-weight:bold;
  line-height:10px;
  border-radius:20px;
  font-size:1.5em;
  background-color: #efefef;
}
button.md-raised.add-question{
  margin-left:333px;
  min-width:30px;
  min-height:30px;
  font-weight:bold;
  line-height:10px;
  border-radius:20px;
  font-size:1.5em;
  color:#fff;
  background-color: #000;
  max-height: 30px;
}
.question-legend{
  background-color: #efefef;
  border-radius: 5px;
  padding:5px;
  width:100%;
  margin-bottom:10px;
  height:40px;
  border:1px solid #c0c0c0;
  overflow: hidden;
}
.question-legend-label{
  padding:4px 0 0 0;
  display:inline-block;
  margin:0 0 0 5px;
  font-weight: bold;
}
.legend-checkbox {
  display: inline-block;
  margin: 6px 0 0 50px;
}
.legend-checkbox-info {
  display: inline-block;
  margin:0;
  padding:4px 0 0 0;
}
.legend-switch-off {
  display: inline-block;
  margin: 2px 0 0 50px;
}
.legend-switch-off-info {
  display: inline-block;
  margin:0;
  padding:4px 0 0 0;
}
.legend-switch-on {
  display: inline-block;
  margin: 2px 0 0 50px;
}
.legend-switch-on-info {
  position: absolute;
  margin:0;
  padding:4px 0 0 0;
}
.question-legend md-checkbox[disabled].md-checked .md-icon {
  background-color: rgba(117,117,117,0.87);
}
.question-legend md-switch[disabled] .md-thumb {
  background-color: #ef242b;
}
.question-legend md-switch[disabled].md-checked .md-thumb {
  background-color: #009049;
}
.question-list td{
  border-right:none;
  border-bottom:1px solid $orange;
  padding:8px;
}
.question-list tr.last td{border-bottom:2px solid #ccc;}
.choices-table td{border-right:none;border-bottom:1px solid #C0C0C0;padding:3px 5px 0 5px;line-height:2em}
.choices-table {border:1px solid #c0c0c0; border-bottom: none;}
.choices-table div.on-off-neutral {
  background-color: #c0c0c0;
  opacity: 0.5;
}
.choices-table div.on-off-neutral,
.choices-table div.on-button,
.choices-table div.off-button{
  margin-top:5px;
}
.choices-table md-checkbox[disabled].md-checked .md-icon {
  background-color: rgba(117,117,117,0.87);
}


.edit-question md-switch{
  .md-thumb{
    background:#f00;
  }

  &.md-checked{
    .md-thumb{
      background:green;
    }
  }
}

md-select-menu md-option:hover{
  background:#eee;
}