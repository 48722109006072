.reset-capacity {
  min-width: 300px;
  border-collapse: collapse;
  text-align: center;

  th, td {
    border: 1px solid $grey;

  }

  th {
    background-color: $grey-very-light;
    text-align: center;
    font-size: 1.25em;
    font-weight: normal;
    padding: 6px;
  }

  td {
    width: 50%;

    &:first-child {
      color: white;
      font-weight: 700;
    }

    &:last-child {
      font-size: 0.75em;

      button {
        padding: 4px 10px;
        border-radius: 6px;
        margin: 4px;
        width: 90%;
      }
    }
  }

  &__repair {
    background-color: $red;
  }

  &__select {
    background-color: $orange;
  }

  &__design {
    background-color: $purple;
  }

  &__all {
    background-color: $black;
  }

  .fa {
    font-size: 20px;
    float: right;
    position: relative;
    right: 20px;
    top: 2px;
  }
}

.reset-dialog {
  p {
    font-weight: 700;
  }
}
