@mixin button($border_color, $background, $background_hover, $background_active) {
  border-radius: 12px;
  padding: 6px 20px;
  background: $background;
  border: 2px solid $border_color;
  outline: none;
  text-transform:uppercase;
  font-weight:700;
  min-height:24px;
  line-height:24px;
  box-sizing:border-box;

  &:hover {
    background: $background_hover;
  }

  &:active {
    background: $background_active;
  }

  &[disabled],
  &.md-raised[disabled], 
  &.md-raised[disabled], 
  &.md-fab[disabled], 
  &.md-fab[disabled]{
    background:#919191;
    color:#ccc;
    border-color:#a1a1a1;
  }

  &.md-raised:not([disabled]),
  &:not([disabled]).md-raised:active, 
  &:not([disabled]).md-fab:active{
    box-shadow:none;
  }

  .md-ripple-container{
    border-radius:12px;
  }
}