.adminTabs {

  md-tab-item:not([disabled]) {
    color: $grey-ll;
  }
}

.big-icon {
  font-size: 24px;
}

.td-action {
  width:50px;
}

.btn-file {
    position: relative;
    overflow: hidden;
}
.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}


.faq-block {
  td:nth-child(2) {
    min-width: 200px;
  }
}

.tips-block {
  &__color {
    display: block;
    background: url('../assets/images/color-skill-builder.png') top left no-repeat;
    background-size:contain;
    background-position:center;
    height: 50px;

    &:hover {
      text-decoration: none;
    }
  }
}

.__users-table {
  max-width:99%;
  .fa {
    font-size: 22px;
    color: $red;

    &.--active {
      color: $green;
    }
  }

  ng-md-icon {
    &[icon="edit"] { color: #000; }
  }

  .__user-details-table {
    width: 50%;

    td {
      padding: 2px 0;

      &:last-child {
        border-bottom: 1px dotted $grey-ll;
      }
    }

    &.__address {
      width: 45%;
      margin-left: 5%;
    }
  }

  .permissions-table {
    width: 100%;
    margin-left: 5%;

    td {
      width: 100%;
      border-bottom: 1px dotted $grey-ll;
      padding: 2px 0;

      &:last-child {
        border-bottom: 0;
      }
    }

    &.__child {
      margin-left: 5%;
    }
  }

  .__permissions {
    td:last-child {
      border: 0 !important;
    }
  }

  .sub-level {
    border: 0 !important;

    &:after {
      content: "";
      display: inline-block;
      margin-left: 5px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 6px 0 6px;
      border-color: $grey-ll transparent transparent transparent;
    }
  }

  .fa.-active{
    color: green;
  }

  .fa-icon-color{
    color:#333;
  }
}

.user-details{
  .fa.-active{
    color: green;
  }
}

.__table-files{
  .big-icon{
    color:#333;
  }
}

.__table-messages,
.__table-tips,
.__table-faq,
.__table-pay-periods{
  ng-md-icon {
    &[icon="edit"] { color: #000; }
  }

  i{
    color:#333;
    font-size:20px;
  }
}

.table-tips {

  &__color {
    display: block;
    background: url('../assets/images/color-skill-builder.png') top left no-repeat;
    background-size:contain;
    background-position:center;
    height: 40px;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
}
md-content.h-100 {
  height: 100%;
}

.bonusBadge {
  color: black;
  img {
    width:28px;
  }
}
