$font-stack:    Helvetica, sans-serif;
$primary-color: #333;

html{}

body {
  font-family: $font-stack;
  color: $primary-color;
  position: relative;
  top:0;
  left:0;
}

.page-header
{
  background: #959595; /* Old browsers */
  background: -moz-linear-gradient(top,  #959595 0%, #0d0d0d 48%, #010101 52%, #0a0a0a 56%, #1b1b1b 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#959595), color-stop(48%,#0d0d0d), color-stop(52%,#010101), color-stop(56%,#0a0a0a), color-stop(100%,#1b1b1b)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  #959595 0%,#0d0d0d 48%,#010101 52%,#0a0a0a 56%,#1b1b1b 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  #959595 0%,#0d0d0d 48%,#010101 52%,#0a0a0a 56%,#1b1b1b 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  #959595 0%,#0d0d0d 48%,#010101 52%,#0a0a0a 56%,#1b1b1b 100%); /* IE10+ */
  background: linear-gradient(to bottom,  #959595 0%,#0d0d0d 48%,#010101 52%,#0a0a0a 56%,#1b1b1b 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#959595', endColorstr='#1b1b1b',GradientType=0 ); /* IE6-9 */
	height:75px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.99);
  -moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.99);
  -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.99);
  position: fixed;
  left:-10px;right:-10px;
  top:-40px;
  z-index:10;
  min-width: 600px;
}

.glob
{
	z-index:0;
	margin-top:5px;
	float:left;
	margin-left:48px;
	height:60px;
}

.logo
{
	position:relative;
	float:left;
  width: 400px;
  left: 10px;
  top: -5px;
}

.tagline
{
	margin-top:15px;
	position:relative;
	top:5px;
	left:-65px;
	float:left;
}
.page-title{
  color: #FFFFFF;
  font-size: 30px !important;
  position: absolute;
  top: 15px;
  right: 50px;
}
