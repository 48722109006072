
// ***** BLACK *****
.ics-black-bg{
  background-color: black;
  color:white;
  font-weight: 800;
}

// ***** GREY *****
.ics-grey-bg{
  background-color: $grey;
  color:black;
  font-weight: 800;
}

.ics-grey-bg.tablesort-sortable:hover,
.ics-tab.ics-grey-bg:hover{
  background-color: $grey-hl;
}

table .ics-grey-bg.tablesort-asc,
table .ics-grey-bg.tablesort-desc{
  background-color: $grey-ll;
  color:#fff;
}

// ***** RED *****
.ics-red-bg, md-toolbar.ics-red-bg {
  background-color: $red;
  color:white;
  font-weight: 800;
}

.ics-red-bg.tablesort-sortable:hover, .ics-tab.ics-red-bg:hover {
  background-color: $red-hl;
}

table .ics-red-bg.tablesort-asc, table .ics-red-bg.tablesort-desc {
  background-color: $red-ll;
}

// ***** ORANGE *****
.ics-orange-bg, md-toolbar.ics-orange-bg {
  background-color: $orange;
  color: white;
  font-weight: 800;
}

.ics-orange-bg.tablesort-sortable:hover, .ics-tab.ics-orange-bg:hover {
  background-color: $orange-hl;
}

table .ics-orange-bg.tablesort-asc, table .ics-orange-bg.tablesort-desc {
  background-color: $orange-ll;
}

// ***** PURPLE *****
.ics-purple-bg, md-toolbar.ics-purple-bg {
  background-color: $purple;
  color: white;
  font-weight: 800;
}

.ics-purple-bg.tablesort-sortable:hover, .ics-tab.ics-purple-bg:hover {
  background-color: $purple-hl;
}

table .ics-purple-bg.tablesort-asc, table .ics-purple-bg.tablesort-desc {
  background-color: $purple-ll;
}

.ics-blue-bg {
  background-color: #0433FF;
  color: white;
  font-weight: 800;
}


md-content.md-default-theme, md-content{
  background:#fff;
}
