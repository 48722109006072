.artistTabs {
  .md-tab {
    width: 150px;

    &:first-child {
      background-color: black;
      color: white;
      font-weight: 800;
      margin-left: 0;
    }

    &:nth-child(2) {
      background-color: $red;
      color: white;
      font-weight: 800;
    }

    &:nth-child(3) {
      background-color: $orange;
      color: white;
      font-weight: 800;
    }

    &:nth-child(4) {
      background-color: $purple;
      color: white;
      font-weight: 800;
    }

    &:nth-child(5) {
      background-color: $pink;
      color: white;
      font-weight: 800;
    }
  }
}

.select-check {
  position: relative;
  top: 10px;
}

#user-profile {
  max-width: 500px;
  position: fixed;
  height:100%;
  right:0;
  top: 80px;
  box-shadow: -2px 0px 8px 0px rgba(0,0,0,0.25);
  background-color: #ffffff;
  z-index:5;

  .user-profile {
    &__toolbar {
      height: 38px;
      max-height: 38px;
      min-height:36px;
      padding:0;
      z-index:10;
      background:#efefef;
    }

    &__info {
      overflow: auto;
      width: 100%;
      position: absolute;
      z-index: 15;
      top: 38px;
      bottom: 0;
    }

    &__capacity {
      md-input-container {
        margin: 0;
        padding: 0;
      }
    }

    &__table {
      width: 100%;
      th, td {
        padding: 3px;
      }
    }

    &__job-type-table {
      width: 50%;
      th, td {
        padding: 3px;
        text-align: left;
      }
    }

    &__pie {
      overflow: hidden;
      margin: -10px 0 0 -16px;
      height: 200px;
      width: 260px;
      z-index: 100;
      position: relative;
      top: -20px;
    }
  }

  &.hide-user{
    visibility: hidden;
    width:0;
    transition: width, .2s linear;
    overflow: auto;
  }
  &.show-left{
    visibility: visible;
    width:100%;
    transition: width, .4s linear;
  }

  md-select:focus:not([disabled]) .md-select-value{
    border-bottom: none;
  }

  .md-select-value *:first-child {
    flex: 1 1 0;
    height: 18px;
    max-width: 40px;
    overflow: hidden;
    text-overflow: clip;
    transform: translate3d(0px, 2px, 0px);
    white-space: normal;
  }

  md-switch{
    margin:2px;
  }

  md-select-value{
    padding:0; border: none;height: 30px;
    margin-top: -5px;
  }

  md-input-container{
    padding:0;
  }

  md-input-container > md-select {
    margin: 0 0;
  }

  h2{
    font-weight:800;
    font-size:.8em;
    line-height: 1.3em;
  }

  .md-errors-spacer {
    display: none;
  }
}

.md-select-value .md-select-icon {
  margin: 0 0 0 -8px;
  width: 14px;
}

md-toast.md-top {
  top: 85px;
  z-index:99;
}
.user-profile-toggle-button{
  border-left: 2px solid #dddddd;
  border-bottom:2px solid #dddddd;
  height:38px;
  min-height: 38px;
  margin-left: -30px;
  margin-top: 0px;
  padding-top: 5px;
  padding-bottom:5px;
  padding-left:0;
  position: absolute;
  width: 40px;
  min-width:40px;
  z-index:9;
  border-radius:0 0 0px 5px;
  background-color: #efefef;
}
.user-profile-toggle-button:hover{

}

.question-answers {
  md-checkbox {
    .md-container {
      top: 0;
    }

    margin-left: 0;
    margin-top: 10px;
    padding-left: 0;
  }
}

.download-search-section{
  position: absolute;
  top: 15px;
  right: 8px;
  z-index: 3;
}

.view-application-modal{
  width:60%;

  table.question-list td{
    vertical-align:middle;
  }

  md-checkbox{
    margin:11px 0 0 0;
    min-height:100%;
    line-height:1;
  }
}

.ics-content-wrapper-navbar.-artists{
  height:auto;

  .btn-download {
    margin-top: 10px;
    border: 1px solid #CCCCCC;
    -webkit-box-shadow: #FEFFFF 1px 1px 1px;
    -moz-box-shadow: #FEFFFF 1px 1px 1px;
    box-shadow: #FEFFFF 1px 1px 1px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 5px;
    font-size: 14px;
    padding: 5px;
    line-height: 25px;
    text-decoration: none;
    text-transform: uppercase;
    display: inline-block;
    font-weight: bold;
    color: #000;
    background-color: #FFFFFF;
    background-image: linear-gradient(to bottom, #FFFFFF, #CCCCCC 75%);

    &:hover {
      background-image: linear-gradient(to top, #FFFFFF, #CCCCCC 75%);
    }
  }
}

.no-scroll{
  overflow:hidden;
  padding:15px;

  md-content.scroll{
    table{
      padding:0;
    }
    padding:0;
    max-height:calc(100vh - 175px);
  }

  .text-no-padding{padding:0;}
}

.bonusBadgeMain {
  margin-top: 4px;
  color: black;
  img {
    width:28px;
    transform: translateY(-3px);
  }
}

.src-sel {
  width: 100px;
  margin: 0 10px 0 0;
}
